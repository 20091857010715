
<template>
	<div class="row">
		<div class="col-md-3">
			<div class="list-return">
				<a class="hva-caret-left" href="#" @click="$router.go(-1)">
					{{$t('general.back')}}
				</a>
			</div>
		</div>
		<div class="col-md-9">
			<h2>{{$t('clonePage.title')}}</h2>
			<h3>{{$t('general.search2')}}</h3>
			<p>{{$t('clonePage.searchIntro')}}</p>
			<input v-model="searchstring" v-on:keyup.enter="search" class="search-text" type="text">
			<button type="button" class="search-button" @click="search"><font-awesome-icon icon="search"/></button>
			<div v-if="error" class="error">{{$t('clonePage.minimum3characters')}}</div>
			<spinner v-if="loading" />
			<template v-if="searchResults !== null">
				<p v-if="searchResults.length === 0">{{$t('general.noResults')}}</p>
				<cloneTable v-else v-model="searchResults" @reuse="copy"></cloneTable>
			</template>
			<cloneConfirmation v-show="showModal" @close="close" :selectedBaseItem="selectedBaseItem">

			</cloneConfirmation>
		</div>
	</div>
</template>
<script>
import cloneConfirmation from "@/components/ClonePage/CloneConfirmation.vue";
import cloneTable from "@/components/ClonePage/CloneTable.vue";
import Spinner from "common/components/General/Spinner.vue";
import { get } from "common/store/storeFunctions";

import "../../node_modules/tablesorter/dist/css/theme.default.min.css";
var $ = require("jquery");
require("tablesorter");
var lengthMustBeGreaterThan2 = value => value.length > 2;

export default {
	name: "clone",
	components: {
		Spinner,
		cloneConfirmation,
		cloneTable
	},
	data: function() {
		return {
			showModal: false,
			loading: false,
			selectedBaseItem: null,
			searchstring: "",
			searchResults: null,
			error: false
		};
	},
	async created() {
	},
	updated() {
		this.initializeTablesorter();
	},
	methods: {
		initializeTablesorter: function() {
			$("table.list").tablesorter({
				widgets: ["zebra", "pager", "filter"],
				widthFixed: false,
			});
		},
		copy: function(item) {
			this.selectedBaseItem = new Object();
			this.selectedBaseItem.CourseCatalogId = item.CourseCatalogId;
			this.selectedBaseItem.hasDraftOrigin = item.hasDraftOrigin;
			this.selectedBaseItem.futureEdit = item.futureEdit;
			this.showModal = true;
		},
		search: async function() {
            var model = this;
            model.searchResults = null;
			if (!lengthMustBeGreaterThan2(model.searchstring)){
				model.error = true;
				return;
			} 
			model.error = false;
			
			model.loading = true;
			var baseItems = await model.getDynamicsLeerjaarItems(model.searchstring)
			model.searchResults = model.convertDynamicsLeerjaarItems(baseItems);
			model.loading = false;
		},
		getDynamicsLeerjaarItems: async function(searchstring) {
			var model = this;
			var years = this.acYears.years.slice(-3).join(';')
            return await get(`api/coursecatalog/getbyQuery/${model.language}/${searchstring}/${years}/${model.isExchange}`);
		},
		convertDynamicsLeerjaarItems: function(baseItems) {
			var model = this;

			var outputarray = [];
			baseItems.forEach(function(item) {
				if (item.Id === model.baseItemId) return;

				var baseItem = new Object();
				baseItem.id = item.Id;
				baseItem.studiedeelid = item.CourseId;
				baseItem.studietitel = item.Title;

				baseItem.studiejaar = item.AcademicYear;

				baseItem.studiegidsnr = item.CourseCatalogItem.CatalogueNumber;

				baseItem.opleiding = item.Program;
				baseItem.CourseCatalogId = item.CourseCatalogItem.Id;
				baseItem.studiedeelid = item.CourseCatalogItem.CourseId;
                baseItem.futureEdit = item.CourseCatalogItem.FutureEdit;
				outputarray.push(baseItem);
			});
			return outputarray;
		},
		close: async function() {
			this.showModal = false;
			this.$router.go(-1);
		}
	}
};
</script>

<style lang="less" scoped>
#app {
	input .search-input {
		padding: 2px 5px;
		margin-right: 10px;
	}
    div.error {
        color:red;
        margin-top:3px;
        margin-left:3px;
        font-size: 14px;
    }
	.search-input {
		margin-right: 5px;
	}

	.hva-caret-left {
		margin-left: 26px;
	}
	.list-return {
		margin: 15px 0px;
	}
	h2 {
		font-family: "Open Sans", Helvetica, Arial, sans-serif;
		font-size: 22px;
		font-weight: 400;
		color: #666;
		letter-spacing: 0.03em;
		text-transform: none;
		margin-top: 11px;
	}
	h3 {
		font-size: 1em;
		margin-top: 20px;
	}
	.search-text {
		padding: 2px 5px;
		height: 38px !important;
		font-size: 1rem;
		border: 1px solid #ced4da !important;
		border-radius: 3px 0 0 3px !important;
		border-right: none !important;
		width: 100%;
		max-width: 400px;
	}
	.tablesorter {
		margin-top: 25px;
	}
}
</style>