<template>
    <table class="list copy-course">
        <thead>
            <tr>
                <th class="sorter-false filter-false action" width="125">{{$t('general.reuseData')}}</th>
                <th width="250">{{$t('general.title')}}</th>
                <th>{{$t('commonLabels.programme')}}</th>
                <th class="filter-select" width="125">{{$t("commonLabels.academicYear")}}</th>
                <th width="125">{{$t("commonLabels.catalogueNumber")}}</th>
                <th width="125">{{$t("commonLabels.courseId")}}</th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="(item, index) in input" :key="item.id + index">
                <td class="action">
                    <button type="button" class="button-primary" @click="$emit('reuse', item)">
                        <font-awesome-icon icon="copy" />{{$t('general.reuseData')}}
                    </button>
                    <font-awesome-icon v-if="item.futureEdit" icon="user-edit" :title="$t('editorbar.futureEdit')" />
                </td>
                <td>{{item.studietitel}}</td>
                <td>{{item.opleiding}}</td>
                <td>{{item.studiejaar}}</td>
                <td>{{item.studiegidsnr}}</td>
                <td>{{item.studiedeelid}}</td>
            </tr>
        </tbody>
    </table>
</template>
<script>
    export default {
        name: "cloneTable",
        props: ['value'],
        components:{
        },
        computed: {
            input: {
                get() {
                    return this.value;
                }
            }
        }
        
    }
</script>
<style lang="less" scoped>
td {
    a {
        color: white;
        background-color: #007C68;
        padding-right: 10px;
        padding-left: 3px;
        padding-bottom: 3px;

        svg{
            margin-right: 5px;
        }
    }
}
.copy-course .action button {
    min-width: 75px;
    padding: 5px 0;
}
</style>