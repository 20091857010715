<template>
    <tr v-if="input.length > 0" class="hvaField">
        <div class="text">
			<h3>{{label}}</h3>
		</div>
        <div class="container-div">
			<div class="data-clean text-content">
                <p v-for="person in input" :key="person.Id">{{person.Name}}</p>
            </div>
        </div>
    </tr>
</template>
<script>

export default  {
  name: "peoplefield",
  props: ['value', 'label'],
  components:{
  },
  computed: {
    input: {
      get() {
        return this.value;
      }
    }
  }
};
</script>