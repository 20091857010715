<template>
    <transition name="modal">
        <div class="modal-mask">
            <div class="modal-wrapper clone"
                 @click="$emit('close')">
                <div class="modal-container"
                     @click.stop>
                    <div class="modal-header">
                        <h2>{{$t('clonePage.title')}}</h2>
                        <button v-if="!allFieldsAreNull"
                                class="modal-default-button button button-primary"
                                @click="copy"
                                type="button">
                            {{$t('clonePage.copyAndSave')}}
                        </button>
                        <button class="modal-default-button button button-secondary"
                                @click="$emit('close')"
                                type="button">
                            {{$t('general.cancel')}}
                        </button>
                    </div>

                    <div class="modal-body"
                         v-if="!objectIsNull(courseCatalogItem)">
                        <table>
                            <tbody>
                                <tr v-if="allFieldsAreNull">{{$t('clonePage.allFieldsAreNull')}}</tr>
                                <tr is="field"
                                    v-model="courseCatalogItem.Description"
                                    :label="$t('commonLabels.description')"></tr>
                                <tr is="field"
                                    v-model="courseCatalogItem.EntryRequirements"
                                    :label="$t('commonLabels.entryCriteria')"></tr>
                                <tr is="field"
                                    v-model="courseCatalogItem.SignUpRules"
                                    :label="$t('commonLabels.signUpRules')"></tr>
                                <tr is="field"
                                    v-model="courseCatalogItem.Language"
                                    :label="$t('commonLabels.language')"></tr>
                                <tr is="field"
                                    v-model="courseCatalogItem.TeachingMethodsDescription"
                                    :label="$t('commonLabels.teachingMethodsRemarks')"></tr>
                                <tr is="field"
                                    v-model="courseCatalogItem.Contacttime"
                                    :label="$t('commonLabels.contactTime')"></tr>
                                <tr is="field"
                                    v-model="courseCatalogItem.Attendance"
                                    :label="$t('commonLabels.presence')"></tr>
                                <tr is="peoplefield"
                                    v-model="courseCatalogItem.Coordinators"
                                    :label="$t('commonLabels.coordinators')"></tr>
                                <tr is="field"
                                    v-model="courseCatalogItem.Coursematerials"
                                    :label="$t('commonLabels.studyMaterial')"></tr>
                                <tr is="field"
                                    v-model="courseCatalogItem.LearningObjectives"
                                    :label="$t('commonLabels.competencies')"></tr>
                                <tr is="field"
                                    v-model="courseCatalogItem.ProfessionalContext"
                                    :label="$t('commonLabels.practice')"></tr>
                                <tr is="field"
                                    v-model="courseCatalogItem.PortfolioProducts"
                                    :label="$t('commonLabels.portfolioProducts')"></tr>
                                <tr is="field"
                                    v-model="courseCatalogItem.CourseLinks"
                                    :label="$t('commonLabels.courseLinks')"></tr>
                                <tr is="field"
                                    v-model="courseCatalogItem.LearningTrack"
                                    :label="$t('commonLabels.learningTrack')"></tr>
                            </tbody>
                        </table>
                    </div>

                    <div class="modal-footer">
                        <slot name="footer">
                            <button v-if="!allFieldsAreNull"
                                    class="modal-default-button button button-primary"
                                    @click="copy"
                                    type="button">
                                {{$t('clonePage.copyAndSave')}}
                            </button>
                            <button class="modal-default-button button button-secondary"
                                    @click="$emit('close')"
                                    type="button">
                                {{$t('general.cancel')}}
                            </button>
                        </slot>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>
<script>
    import field from "@/components/ClonePage/Field.vue";
    import peoplefield from "@/components/ClonePage/PeopleField.vue";
    import { get, put } from 'common/store/storeFunctions.js'

    export default {
        name: "cloneconfirmation",
        components: { field, peoplefield },
        props: ["selectedBaseItem"],
        data: function () {
            return {
                courseCatalogItem: null,
                futureEdit: false
            };
        },
        computed: {
            allFieldsAreNull() {
                if (
                    this.objectIsNull(this.courseCatalogItem) ||
                    (this.courseCatalogItem.Description === null &&
                        this.courseCatalogItem.EntryRequirements === null &&
                        this.courseCatalogItem.SignUpRules === null &&
                        this.courseCatalogItem.Language === null &&
                        this.courseCatalogItem.TeachingMethodsDescription === null &&
                        this.courseCatalogItem.Contacttime === null &&
                        this.courseCatalogItem.Coordinators.length === 0 &&
                        this.courseCatalogItem.Coursematerials === null &&
                        this.courseCatalogItem.LearningObjectives === null &&
                        this.courseCatalogItem.ProfessionalContext === null &&
                        this.courseCatalogItem.PortfolioProducts === null &&
                        this.courseCatalogItem.CourseLinks === null &&
                        this.courseCatalogItem.LearningTrack === null)
                )
                    return true;
                else {
                    return false;
                }
            }
        },
        watch: {
            selectedBaseItem: async function () {
                var model = this;
                if (model.selectedBaseItem.futureEdit) {
                    model.futureEdit = true;
                    model.courseCatalogItem = await get(`api/coursecatalog/getfutureedit/${model.selectedBaseItem.CourseCatalogId}/false`);
                } else {
                    model.courseCatalogItem = await get(`api/coursecatalog/get/${model.selectedBaseItem.CourseCatalogId}`);
                }
            }
        },
        methods: {
            copy: async function () {
                var model = this;
                var programBaseItemToBeClonedTo = this.$store.state.programBaseItemToBeClonedTo;
                model.courseCatalogItem.Id = programBaseItemToBeClonedTo.CourseCatalogItem.Id;
                model.courseCatalogItem.ModifiedOn = programBaseItemToBeClonedTo.CourseCatalogItem.ModifiedOn;
                model.courseCatalogItem.EditorialState = programBaseItemToBeClonedTo.CourseCatalogItem.EditorialState;

                await put(`/api/coursecatalog/update`, model.courseCatalogItem);
                model.$emit("close");
            }
        }
    };
</script>
<style lang="less" scoped>
    #app {
        .modal-mask

    {
        position: fixed;
        z-index: 9998;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        display: table;
        transition: opacity 0.3s ease;
    }

    .modal-wrapper {
        display: table-cell;
        vertical-align: middle;
    }

    .modal-container {
        width: 80%;
        margin: 0px auto;
        padding: 20px 30px;
        background-color: #fff;
        border-radius: 2px;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
        transition: all 0.3s ease;
        font-family: Helvetica, Arial, sans-serif;
        max-height: 80vh;
        margin-top: 10%;
        margin-bottom: 10%;
        overflow-y: auto;
    }

    .modal-header h3 {
        margin-top: 0;
        color: #42b983;
    }

    .modal-body {
        margin: 0;
    }

    .modal-default-button {
        float: right;
    }

    /*
    * The following styles are auto-applied to elements with
    * transition="modal" when their visibility is toggled
    * by Vue.js.
    *
    * You can easily play with the modal transition by editing
    * these styles.
    */

    .modal-enter {
        opacity: 0;
    }

    .modal-leave-active {
        opacity: 0;
    }

        .modal-enter .modal-container,
        .modal-leave-active .modal-container {
            -webkit-transform: scale(1.1);
            transform: scale(1.1);
        }

    a.readmore,
    a.readmore:visited {
        color: blue;
    }

    table {
        width: 100%;
    }

    .clone .modal-header {
        padding-left: 0;
        padding-right: 0;
    }

        .clone .modal-header h2 {
            color: #25167a;
            font-size: 1.25em;
        }

    .clone .modal-body,
    .clone .modal-footer {
        padding-left: 0;
        padding-right: 0;
    }

    .modal-container {
        max-width: 1024px;
    }

    .hvaField {
        padding-bottom: 15px;
        display: inline-block;
        width: 100%;
    }

    }

    .bootstrap-hva .modal-header button {
        white-space: nowrap;
    }

    .bootstrap-hva .modal-header > :not(:last-child) {
        margin-right: 0.25rem;
    }
</style>
